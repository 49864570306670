import * as React from 'react';

import useCurrentUser from 'hooks/useCurrentUser';
import { ColumnSort, ColumnFilter, TH } from 'security-app/components/common';
import { GLCheckbox } from 'common/components';
import type { ColumnFilterData, FilterData } from 'security-app/components/common/Filters/ColumnFilter.types';
import {
  usePagination,
  useSetPagination,
  useSelectedRows,
  useSelectedRowsDispatch,
} from 'common/contexts';
import { useGetSigmaRules } from 'security-app/hooks/useSigmaAPI';
import type { SigmaRuleListAPIType } from 'security-app/hooks/api/sigmaAPI.types';

type Props = {
  displayRowActions: boolean,
  rules: SigmaRuleListAPIType[],
  visibleColumns: Array<string>,
};

const columnsWithFilter = [
  'level',
  'status',
  'last_run',
  'updated_at',
  'enabled',
];

function ListHeader({ rules, visibleColumns, displayRowActions }: Props) {
  const { permissions } = useCurrentUser();
  const pagination = usePagination();
  const setPagination = useSetPagination();

  const canManageRules = React.useMemo(() => (
    permissions.includes('sigma_rule:edit') || permissions.includes('*')
  ), [permissions]);

  const selectedRules = useSelectedRows();
  const selectedRulesDispatch = useSelectedRowsDispatch();

  const { rules: filtersData } = useGetSigmaRules({ page: 1, perPage: 10000 });

  const filtersByColumn = React.useMemo(() => (
    filtersData.reduce((acc: ColumnFilterData, rule: SigmaRuleListAPIType) => {
      Object.entries(rule).filter(([key, _]) => columnsWithFilter.includes(key)).forEach(([key, value]) => {
        if (value == null) return;
        if (!acc[key]) acc[key] = [];
        const filterValue = value.toString();
        const valueType = typeof value;

        if (!acc[key].find((filter: FilterData) => filter.value.toString() === filterValue)) {
          acc[key].push({ label: value.toString(), value: filterValue, type: valueType });
        }
      });

      return acc;
    }, {} as ColumnFilterData)
  ), [filtersData]);

  const applySort = (field: string, direction: string) => {
    setPagination({ ...pagination, orderBy: field, direction });
  };

  const applyFilters = (filterKey: string, filterValues: FilterData[]) => {
    const auxFilters = { ...pagination.filters, [filterKey]: filterValues };
    if (!filterValues || filterValues.length === 0) delete auxFilters[filterKey];

    setPagination({ ...pagination, filters: auxFilters });
  };

  const selectAll = (e: React.BaseSyntheticEvent) => {
    if (e.target.checked) {
      selectedRulesDispatch({ type: 'add', payload: rules });
    } else {
      selectedRulesDispatch({ type: 'remove', payload: rules });
    }
  };

  const allSelected = React.useMemo(() => (
    rules.length > 0
    && selectedRules.length > 0
    && rules.every((rule: SigmaRuleListAPIType) => (
      !!selectedRules.find((sRule: SigmaRuleListAPIType) => rule.id === sRule.id)
    ))
  ), [rules, selectedRules]);

  const columnIsVisible = (columnName: string) => visibleColumns.includes(columnName);

  return (
    <thead>
      <tr>
        {canManageRules ? (
          <TH style={{ width: 32 }}><GLCheckbox checked={allSelected} onChange={selectAll} title="Select all sigma rules" /></TH>
        ) : <TH />}
        {columnIsVisible('title') && (
          <TH>
            <ColumnSort field="parsed_rule.title"
                        orderBy={pagination.orderBy}
                        direction={pagination.direction}
                        onSort={applySort}>
              Rule
            </ColumnSort>
          </TH>
        )}
        {columnIsVisible('level') && (
          <TH>
            <ColumnSort field="parsed_rule.level"
                        orderBy={pagination.orderBy}
                        direction={pagination.direction}
                        onSort={applySort}>
              <ColumnFilter filterBy="level"
                            type="text"
                            filterData={filtersByColumn.level}
                            appliedFilters={pagination.filters}
                            onClose={applyFilters}>
                Level
              </ColumnFilter>
            </ColumnSort>
          </TH>
        )}
        {columnIsVisible('status') && (
          <TH>
            <ColumnSort field="parsed_rule.status"
                        orderBy={pagination.orderBy}
                        direction={pagination.direction}
                        onSort={applySort}>
              <ColumnFilter filterBy="status"
                            type="text"
                            filterData={filtersByColumn.status}
                            appliedFilters={pagination.filters}
                            onClose={applyFilters}>
                Status
              </ColumnFilter>
            </ColumnSort>
          </TH>
        )}
        {columnIsVisible('last_run') && (
          <TH>
            <ColumnSort field="last_run"
                        orderBy={pagination.orderBy}
                        direction={pagination.direction}
                        onSort={applySort}>
              <ColumnFilter filterBy="last_run"
                            type="date"
                            filterData={filtersByColumn.last_run}
                            appliedFilters={pagination.filters}
                            onClose={applyFilters}>
                Last Run
              </ColumnFilter>
            </ColumnSort>
          </TH>
        )}
        {columnIsVisible('updated_at') && (
          <TH>
            <ColumnSort field="updated_at"
                        orderBy={pagination.orderBy}
                        direction={pagination.direction}
                        onSort={applySort}>
              <ColumnFilter filterBy="updated_at"
                            type="date"
                            filterData={filtersByColumn.updated_at}
                            appliedFilters={pagination.filters}
                            onClose={applyFilters}>
                Last Update
              </ColumnFilter>
            </ColumnSort>
          </TH>
        )}
        {columnIsVisible('enabled') && (
          <TH style={{ width: 65 }}>
            <ColumnSort field="enabled"
                        orderBy={pagination.orderBy}
                        direction={pagination.direction}
                        onSort={applySort}>
              <ColumnFilter filterBy="enabled"
                            type="text"
                            filterData={filtersByColumn.enabled}
                            appliedFilters={pagination.filters}
                            onClose={applyFilters}>
                Enabled
              </ColumnFilter>
            </ColumnSort>
          </TH>
        )}
        {displayRowActions && <TH style={{ width: 32 }} />}
      </tr>
    </thead>
  );
}

export default ListHeader;
